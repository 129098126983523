import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "@ui/wrapper";
import Image from "@ui/image";
import SectionTitle from "@ui/section-title";
import Text from "@ui/text";
import { SectionWrap } from "./style";

const AboutArea = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "foto-y-video.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  const foto = data.file.childImageSharp.gatsbyImageData;
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col>
            <SectionTitle
              mb="30px"
              title="Fotografía y Video"
              subtitle="Tengamos un día mágico"
              description="¿Estás buscando un fotógrafo y video para eventos? Si es así, podemos ayudarte."
            />
          </Col>
        </Row>
        <Row justifyContent="center" mt="30px">
          <Col lg={10}>
            <Image src={foto} alt="foto y video" />
          </Col>
        </Row>
        <Row justifyContent="center" mt="30px">
          <Col lg={8}>
            <Text>
              Al contratar a un fotógrafo de eventos, está seleccionando algo
              más que un fotógrafo para su día o evento especial. Está
              contratando a un fotógrafo que interactuará con usted, su familia
              y sus amigos. Necesita contratar a alguien con experiencia que
              pueda adaptarse a cualquier situación. Estas son solo algunas de
              las muchas razones por las que necesita contratar a un fotógrafo
              profesional que satisfaga sus necesidades. DK Catering & Eventos
              tiene años de experiencia trabajando con familias y eventos en
              todo Lima. Nos especializamos en todo tipo de fotografía para
              eventos. Esto incluye cumpleaños, bodas, quinceañeros,
              bautizos/bautizos, graduación y eventos corporativos. Vea los
              paquetes de fotografía de DK Catering & Eventos y elija uno que se
              adapte a sus necesidades. No dude en llamar o enviar un mensaje
              utilizando nuestro formulario de contacto. Si tiene alguna
              pregunta, eche un vistazo a la sección de Preguntas frecuentes.
            </Text>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default AboutArea;
