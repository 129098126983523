import * as React from "react";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-03";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@ui/page-header/layout-02";
import AboutArea from "@containers/foto-y-video/about-area";
import Grid from "@containers/foto-y-video/grid";
import Recordar from "@containers/foto-y-video/recordar";

const FotoYVideoPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Foto y Video" />
      <Header transparent />
      <main className="site-wrapper-reveal">
        <PageHeader title="Foto y Video" />
        <AboutArea />
        <Grid />
        <Recordar />
      </main>
      <Footer />
    </Layout>
  );
};

export default FotoYVideoPage;
