import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "@ui/wrapper";
import Gallery from "@browniebroke/gatsby-image-gallery";

const Grid = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "grid" } }) {
        edges {
          node {
            childImageSharp {
              thumb: gatsbyImageData(
                width: 380
                height: 270
                placeholder: BLURRED
              )
              full: gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);

  const images = data.allFile.edges.map(({ node }) => node.childImageSharp);
  return (
    <Container fluid>
      <Row>
        <Col>
          <Gallery
            images={images}
            gutter={0}
            colWidth={100 / 4}
            mdColWidth={100 / 6}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Grid;
