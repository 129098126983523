import React from "react";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Text from "@ui/text";
import { SectionWrap } from "./style";

const Recordar = () => {
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col>
            <SectionTitle mb="30px" title="Recordar..." />
          </Col>
        </Row>

        <Row justifyContent="center" mt="30px">
          <Col lg={8}>
            <Text>
              Uno de los días más importantes de tu vida. Fotografío compromisos
              de todo tipo, bodas pequeñas, grandes y eventos corporativos. No
              importa cuál sea tu visión, hay un lugar que se adaptará a ella, y
              me encantaría ayudarte a descubrirlo y llevarlo a cabo. Siempre me
              considero muy afortunado de poder aparecer en momentos tan
              maravillosos en la vida de las personas, en un lugar tan
              magnífico, y de hacer mi parte para ayudar a que sea lo más
              especial posible. No dudes en ponerte en contacto conmigo
              directamente para explicar tus ideas, y estaré encantado de crear
              un plan y enviarle un presupuesto.
            </Text>
            <Text as="span">- Mario Rivera</Text>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default Recordar;
